export default {
  cake: {
    56: '0xc242b6A3983e90D279ed68aC70f47CFC7e5e1e1C',
    97: '',
  },
  rcake: {
    56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    97: '',
  },
  auto: {
    56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
    97: '',
  },
  masterChef: {
    56: '0x32b8065A5E0223298DbaB8337b2d8901107b52f5',
    97: '',
  },
  wbnb: {
    56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    97: '',
  },
  lottery: {
    56: '0x2bc91B35e7C6c644BdB70B639069b6F070CbfBA4',
    97: '',
  },
  lotteryNFT: {
    56: '0x2167aaeae5a7BD79C7c4CEd1a21A55A93ffc89f2',
    97: '',
  },
  mulltiCall: {
    56: '0x1ee38d535d541c55c9dae27b12edf090c608e6fb',
    97: '0x67ADCB4dF3931b0C5Da724058ADC2174a9844412',
  },
  busd: {
    56: '0xe9e7cea3dedca5984780bafc599bd69add087d56',
    97: '',
  },
}
