import contracts from './contracts'
import { FarmConfig, QuoteToken } from './types'

const farms: FarmConfig[] = [
  {
    pid: 0,
    risk: 5,
    lpSymbol: 'PTLK-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x67beeebba73e5b479eae6945c59e26ffe5e75e0f',
    },
    tokenSymbol: 'PTLK',
    tokenAddresses: {
      97: '',
      56: '0xc242b6A3983e90D279ed68aC70f47CFC7e5e1e1C',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 1,
    risk: 5,
    lpSymbol: 'PTLK-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x97f547aa7fdcc091f1636e6b3fa255bcb586c199',
    },
    tokenSymbol: 'PTLK',  
    tokenAddresses: {
      97: '',
      56: '0xc242b6A3983e90D279ed68aC70f47CFC7e5e1e1C',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 2,
    risk: 5,
    lpSymbol: 'BNB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x1b96b92314c44b159149f7e0303511fb2fc4774f',
    },
    tokenSymbol: 'BNB',
    tokenAddresses: {
      97: '',
      56: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 17,
    risk: 1,
    lpSymbol: 'PTLK-CAKE LP',
    lpAddresses: {
      97: '',
      56: '0xed7b4e91f85a4c9563cdaa619b8989c4e96c2559',
    },
    tokenSymbol: 'PTLK',
    tokenAddresses: {
      97: '',
      56: '0xc242b6A3983e90D279ed68aC70f47CFC7e5e1e1C',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 5,
    risk: 2,
    lpSymbol: 'LINK-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xaebe45e3a03b734c68e5557ae04bfc76917b4686',
    },
    tokenSymbol: 'LINK',
    tokenAddresses: {
      97: '',
      56: '0xf8a0bf9cf54bb92f17374d9e9a321e6a111a51bd',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 6,
    risk: 2,
    lpSymbol: 'DOT-BNB LP',
    lpAddresses: {
      97: '',
      56: '0xbcd62661a6b1ded703585d3af7d7649ef4dcdb5c',
    },
    tokenSymbol: 'DOT',
    tokenAddresses: {
      97: '',
      56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },
  {
    pid: 22,
    risk: 1,
    lpSymbol: 'PTLK-AUTO LP',
    lpAddresses: {
      97: '',
      56: '0x59dd71fdd7615fc6822c147e63a6c32c465a412e',
    },
    tokenSymbol: 'PTLK',
    tokenAddresses: {
      97: '',
      56: '0xc242b6A3983e90D279ed68aC70f47CFC7e5e1e1C',
    },
    quoteTokenSymbol: QuoteToken.CAKE,
    quoteTokenAdresses: contracts.cake,
  },
  {
    pid: 23,
    risk: 1,
    lpSymbol: 'SLSH-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x28062de9e27b6424a9fe95436a7b34de63bf95b7',
    },
    tokenSymbol: 'SLSH',
    tokenAddresses: {
      97: '',
      56: '0xf76e1b7ff23b462bf9f0562dcf7929c032c84ffb',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 32,
    risk: 1,
    lpSymbol: 'SLSH-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x1467ba00034cac6c489c79238f77da444774cb2d',
    },
    tokenSymbol: 'SLSH',
    tokenAddresses: {
      97: '',
      56: '0xf76e1b7ff23b462bf9f0562dcf7929c032c84ffb',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 33,
    risk: 1,
    lpSymbol: 'Tezos-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x5acac332f0f49c8badc7afd0134ad19d3db972e6',
    },
    tokenSymbol: 'XTZ',
    tokenAddresses: {
      97: '',
      56: '0x16939ef78684453bfdfb47825f8a5f714f12623a',
    },
    quoteTokenSymbol: QuoteToken.BNB,
    quoteTokenAdresses: contracts.wbnb,
  },

  {
    pid: 34,
    risk: 1,
    lpSymbol: 'LTC-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x921ca483796889c77af03518b222c2bcf4371114',
    },
    tokenSymbol: 'LTC',
    tokenAddresses: {
      97: '',
      56: '0x4338665cbb7b2485a8855a139b75d5e34ab0db94',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },

  {
    pid: 35,
    risk: 1,
    lpSymbol: 'DOGE-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x1efcb446bfa553a2eb2fff99c9f76962be6ecac3',
    },
    tokenSymbol: 'DOGE',
    tokenAddresses: {
      97: '',
      56: '0xba2ae424d960c26247dd6c32edc70b295c744c43',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 38,
    risk: 1,
    lpSymbol: 'SHIB-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x948d9d18d82e9d819ddaaa7e1ae3d3dff8a862e3',
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      97: '',
      56: '0xf09a100c32d780f0a48754e43533f9fb374e85f9',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
    {
    pid: 40,
    risk: 1,
    lpSymbol: 'NRV-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x820b7d71218a74297645f0fec0b9de85dcca043c',
    },
    tokenSymbol: 'NRV',
    tokenAddresses: {
      97: '',
      56: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 42,
    risk: 1,
    lpSymbol: 'CUMMIES-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x90ed624c3a25ab3507e4f47b776711e2f3ad1856',
    },
    tokenSymbol: 'CUMMIES',
    tokenAddresses: {
      97: '',
      56: '0x27ae27110350b98d564b9a3eed31baebc82d878d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 44,
    risk: 1,
    lpSymbol: 'KEIF-BNB LP',
    lpAddresses: {
      97: '',
      56: '0x0259f78f838e15d9e9a6004535a145223c1e538e',
    },
    tokenSymbol: 'KEIF',
    tokenAddresses: {
      97: '',
      56: '0x81bDb466317fE8E8559C67023bA5d83C37a2b098',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 45,
    risk: 1,
    lpSymbol: 'KEIF-BUSD LP',
    lpAddresses: {
      97: '',
      56: '0x7b84cda65daaac2f0f0851d1ef662d3d30740690',
    },
    tokenSymbol: 'KEIF',
    tokenAddresses: {
      97: '',
      56: '0x81bDb466317fE8E8559C67023bA5d83C37a2b098',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 3,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'PTLK',
    lpAddresses: {
      97: '',
      56: '0x67beeebba73e5b479eae6945c59e26ffe5e75e0f', // SPAZ-BUSD LP
    },
    tokenSymbol: 'PTLK',
    tokenAddresses: {
      97: '',
      56: '0xc242b6A3983e90D279ed68aC70f47CFC7e5e1e1C',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 13,
    risk: 4,
    isTokenOnly: true,
    lpSymbol: 'CAKE',
    lpAddresses: {
      97: '',
      56: '0x0ed8e0a2d99643e1e65cca22ed4424090b8b7458', // CAKE-BUSD LP
    },
    tokenSymbol: 'CAKE',
    tokenAddresses: {
      97: '',
      56: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 9,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'BUNNY',
    lpAddresses: {
      97: '',
      56: '0xec7a69a3a5ee177c84855c86cc926ca0ba6275cc', // BUNNY-BUSD LP (BUSD-BUSD will ignore)
    },
    tokenSymbol: 'BUNNY',
    tokenAddresses: {
      97: '',
      56: '0xc9849e6fdb743d08faee3e34dd2d1bc69ea11a51',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 16,
    risk: 3,
    isTokenOnly: true,
    lpSymbol: 'BRY',
    lpAddresses: {
      97: '',
      56: '0x34e470519d511f58f1b7b19c4bed988b41976109', // BNB-BUSD LP
    },
    tokenSymbol: 'BRY',
    tokenAddresses: {
      97: '',
      56: '0xf859Bf77cBe8699013d6Dbc7C2b926Aaf307F830',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 15,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'WATCH',
    lpAddresses: {
      97: '',
      56: '0x2d0b3ab51bcb7e36489a0b12980f761d3bd3efd0', // WATCH-BNB LP
    },
    tokenSymbol: 'WATCH',
    tokenAddresses: {
      97: '',
      56: '0x7a9f28eb62c791422aa23ceae1da9c847cbec9b0',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 36,
    risk: 5,
    isTokenOnly: true,
    lpSymbol: 'TRIAS',
    lpAddresses: {
      97: '',
      56: '0xe8818cb84178d41fc1afbb9cbef0fb2ede269201', // TRIAS-BUSD LP
    },
    tokenSymbol: 'TRIAS',
    tokenAddresses: {
      97: '',
      56: '0xa4838122c683f732289805FC3C207Febd55BabDD',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
 
  {
    pid: 37,
    risk: 4,
    isTokenOnly: true,
    lpSymbol: 'PNIXS',
    lpAddresses: {
      97: '',
      56: '0x870b37cdd7698f0561f1d085250aca5764911046', // PNIXS-BUSD LP
    },
    tokenSymbol: 'PNIXS',
    tokenAddresses: {
      97: '',
      56: '0x509949c4ee0C1C2c765CF9B23c75D823981E22aE',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 39,
    risk: 4,
    isTokenOnly: true,
    lpSymbol: 'SHIB',
    lpAddresses: {
      97: '',
      56: '0x948d9d18d82e9d819ddaaa7e1ae3d3dff8a862e3', // PNIXS-BUSD LP
    },
    tokenSymbol: 'SHIB',
    tokenAddresses: {
      97: '',
      56: '0xf09a100c32d780f0a48754e43533f9fb374e85f9',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 41,
    risk: 4,
    isTokenOnly: true,
    lpSymbol: 'NRV',
    lpAddresses: {
      97: '',
      56: '0x820b7d71218a74297645f0fec0b9de85dcca043c', // PNIXS-BUSD LP
    },
    tokenSymbol: 'NRV',
    tokenAddresses: {
      97: '',
      56: '0x42F6f551ae042cBe50C739158b4f0CAC0Edb9096',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  {
    pid: 43,
    risk: 1,
    isTokenOnly: true,
    lpSymbol: 'CUMMIES',
    lpAddresses: {
      97: '',
      56: '0x90ed624c3a25ab3507e4f47b776711e2f3ad1856',
    },
    tokenSymbol: 'CUMMIES',
    tokenAddresses: {
      97: '',
      56: '0x27ae27110350b98d564b9a3eed31baebc82d878d',
    },
    quoteTokenSymbol: QuoteToken.BUSD,
    quoteTokenAdresses: contracts.busd,
  },
  // {
  //   pid: 46,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'KEIF',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x0259f78f838e15d9e9a6004535a145223c1e538e', // KEFI-BUSD LP
  //   },
  //   tokenSymbol: 'KEIF',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x81bDb466317fE8E8559C67023bA5d83C37a2b098',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 19,
  //   risk: 1,
  //   isTokenOnly: true,
  //   lpSymbol: 'USDC',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x680dd100e4b394bda26a59dd5c119a391e747d18', // USDC-BUSD LP
  //   },
  //   tokenSymbol: 'USDC',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 20,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'DOT',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x54c1ec2f543966953f2f7564692606ea7d5a184e', // DOT-BUSD LP
  //   },
  //   tokenSymbol: 'DOT',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x7083609fce4d1d8dc0c979aab8c869ea2c873402',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  
  // {
  //   pid: 22,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'BSCX',
  //   lpAddresses: {
  //     97: '',
  //     56: '0xa32a983a64ce21834221aa0ad1f1533907553136', // BSCX-BUSD LP
  //   },
  //   tokenSymbol: 'BSCX',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0x5ac52ee5b2a633895292ff6d8a89bb9190451587',
  //   },
  //   quoteTokenSymbol: QuoteToken.BUSD,
  //   quoteTokenAdresses: contracts.busd,
  // },
  // {
  //   pid: 23,
  //   risk: 3,
  //   isTokenOnly: true,
  //   lpSymbol: 'AUTO',
  //   lpAddresses: {
  //     97: '',
  //     56: '0x4d0228ebeb39f6d2f29ba528e2d15fc9121ead56', // AUTO-BNB LP
  //   },
  //   tokenSymbol: 'AUTO',
  //   tokenAddresses: {
  //     97: '',
  //     56: '0xa184088a740c695e156f91f5cc086a06bb78b827',
  //   },
  //   quoteTokenSymbol: QuoteToken.BNB,
  //   quoteTokenAdresses: contracts.wbnb,
  // },
]

export default farms
