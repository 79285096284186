import { MenuEntry } from '@pancakeswap-libs/uikit'

const config: MenuEntry[] = [
  {
    label: 'Home',
    icon: 'HomeIcon',
    href: '/',
  },
  {
    label: 'Trade',
    icon: 'TradeIcon',
    items: [
      {
        label: 'Exchange',
        href: 'https://exchange.potluckprotocol.com/',
      },
      {
        label: 'Liquidity',
        href: 'https://exchange.potluckprotocol.com/pool',
      },
    ],
  },
  {
    label: 'Farms',
    icon: 'FarmIcon',
    href: '/farms',
  },
  {
    label: 'Pools',
    icon: 'PoolIcon',
    href: '/nests',
  },

  // {
  //   label: 'Lottery',
  //   icon: 'TicketIcon',
  //   href: '/lottery',
  // },

  {
    label: 'Private Sale',
    icon: 'NftIcon',
    href: 'https://presale.potluckprotocol.com/',
  },
  // {
  //   label: 'NFT',
  //   icon: 'NftIcon',
  //   href: '/nft',
  // },
  {
    label: 'Info',
    icon: 'InfoIcon',
    items: [
      {
        label: 'TokenInfo',
        href: 'https://pancakeswap.info/token/0xc242b6A3983e90D279ed68aC70f47CFC7e5e1e1C',
      },
      // {
      //   label: 'CoinGecko',
      //   href: 'https://www.coingecko.com/en/coins/goose-finance',
      // },
      // {
      //   label: 'CoinMarketCap',
      //   href: 'https://coinmarketcap.com/currencies/goose-finance/',
      // },
      {
        label: 'Twitter',
        href: 'https://twitter.com/PotluckProtocol',
      },
    ],
  },
  {
    label: 'More',
    icon: 'MoreIcon',
    items: [
      {
        label: 'LitePaper',
        href: 'https://pdfhost.io/v/ztHkaubHk_PTLK_LITESHEETpdf.pdf',
      },
      {
        label: 'RoadMap',
        href: 'https://imgbox.com/5ea1ItyW',
      },
      {
        label: 'Medium',
        href: 'https://medium.com/@potluckprotocol',
      },
    ],
  },
  {
    label: 'Slasher',
    icon: 'HamburgerIcon',
    href: 'https://slasherbsc.com/',
  },
  // {
  //   label: 'Purchase',
  //   icon: 'AuditIcon',
  //   href: 'https://slasher.potluckprotocol.com',
  // },
  {
    label: 'Sonar (coming soon)',
    icon: 'HamburgerCloseIcon',
    href: 'https://potluckprotocol.com/#/',
  },
]

export default config
